import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import Qs from 'qs';
import {
  endpoint,
  errorHandler,
} from './restCallUtil';
import { clearAllAuthData, getAuthToken, getSelectedRole } from './AuthUtil';

export function getLoginToken(school: string, ssoToken: string): Promise<AxiosResponse> {
  return new Promise((resolve, reject) => {
    const data = { sso_token: ssoToken };
    axios.post(endpoint(`${school}/sso/login`), data)
      .then((result: any) => {
        resolve(result?.data);
      })
      .catch((error: any) => {
        errorHandler(error);
        reject(error?.response);
      });
  });
}

export function refreshLoginToken(url: string): Promise<AxiosResponse> {
  return new Promise((resolve, reject) => {
    axios.post(url)
      .then((result: any) => {
        resolve(result?.data);
      })
      .catch((error: any) => {
        errorHandler(error);
        reject(error?.response);
      });
  });
}

export function loginUser(loginToken: string): Promise<AxiosResponse> {
  return new Promise((resolve, reject) => {
    const data = { login_token: loginToken };
    axios.post(endpoint('users/web/login'), data)
      .then((result: any) => {
        resolve(result?.data);
      })
      .catch((error: any) => {
        errorHandler(error);
        reject(error?.response);
      });
  });
}

export function logoutUser(): Promise<AxiosResponse> {
  return new Promise((resolve, reject) => {
    const config: AxiosRequestConfig = {
      headers: {
        Authorization: `Bearer ${getAuthToken()}`,
      },
    };
    axios.post(endpoint('users/logout'), {}, config)
      .then((result: any) => {
        clearAllAuthData();
        resolve(result?.data);
      })
      .catch((error: any) => {
        // if logout returns 401 - we clear user auth data and redirect /login
        if (error?.response?.status === 401) {
          clearAllAuthData();
          window.location.href = '/login';
          return;
        }

        errorHandler(error);
        reject(error?.response);
      });
  });
}

export function getCurrentUser(sendRole = false): Promise<AxiosResponse> {
  return new Promise((resolve, reject) => {
    const headers: any = {
      Authorization: `Bearer ${getAuthToken()}`,
    };

    if (sendRole) {
      const selRole = getSelectedRole();
      headers['X-Role-Context'] = selRole?.id || -1;
    }

    const config: AxiosRequestConfig = { headers };
    axios.get(endpoint('users/me'), config)
      .then((result: any) => {
        resolve(result?.data);
      })
      .catch((error: any) => {
        errorHandler(error);
        reject(error?.response);
      });
  });
}

export function getCurrentUserDivisions(): Promise<AxiosResponse> {
  return new Promise((resolve, reject) => {
    const selRole = getSelectedRole();
    const config: AxiosRequestConfig = {
      headers: {
        Authorization: `Bearer ${getAuthToken()}`,
        'X-Role-Context': selRole?.id || -1,
      },
    };
    axios.get(endpoint('users/divisions'), config)
      .then((result: any) => {
        resolve(result?.data);
      })
      .catch((error: any) => {
        errorHandler(error);
        reject(error?.response);
      });
  });
}

export function getCurrentUserDivisionYear(): Promise<AxiosResponse> {
  return new Promise((resolve, reject) => {
    const selRole = getSelectedRole();
    const config: AxiosRequestConfig = {
      headers: {
        Authorization: `Bearer ${getAuthToken()}`,
        'X-Role-Context': selRole?.id || -1,
      },
    };
    axios.get(endpoint('divisions/years'), config)
      .then((result: any) => {
        resolve(result?.data);
      })
      .catch((error: any) => {
        errorHandler(error);
        reject(error?.response);
      });
  });
}

export function getAllDivisions(): Promise<AxiosResponse> {
  return new Promise((resolve, reject) => {
    const selRole = getSelectedRole();
    const config: AxiosRequestConfig = {
      headers: {
        Authorization: `Bearer ${getAuthToken()}`,
        'X-Role-Context': selRole?.id || -1,
      },
    };
    axios.get(endpoint('divisions'), config)
      .then((result: any) => {
        resolve(result?.data);
      })
      .catch((error: any) => {
        errorHandler(error);
        reject(error?.response);
      });
  });
}

export function getCurrentUserStudents(): Promise<AxiosResponse> {
  return new Promise((resolve, reject) => {
    const selRole = getSelectedRole();
    const config: AxiosRequestConfig = {
      headers: {
        Authorization: `Bearer ${getAuthToken()}`,
        'X-Role-Context': selRole?.id || -1,
      },
    };
    axios.get(endpoint('users/students'), config)
      .then((result: any) => {
        resolve(result?.data);
      })
      .catch((error: any) => {
        errorHandler(error);
        reject(error?.response);
      });
  });
}

export function getNotices(
  divisionId: number,
  studentId: number,
  pageNumber: number = 1,
): Promise<AxiosResponse> {
  return new Promise((resolve, reject) => {
    const selRole = getSelectedRole();
    const headers: any = {
      Authorization: `Bearer ${getAuthToken()}`,
      'X-Role-Context': selRole?.id || -1,
    };

    if (divisionId > 0) {
      headers['X-Division-Context'] = divisionId;
    }

    if (studentId > 0) {
      headers['X-Student-Context'] = studentId;
    }
    const config: AxiosRequestConfig = { headers };
    const url = endpoint(`notices${pageNumber > 1 ? `?page=${pageNumber}` : ''}`);
    axios.get(url, config)
      .then((result: any) => {
        resolve(result?.data);
      })
      .catch((error: any) => {
        errorHandler(error);
        reject(error?.response);
      });
  });
}

export function getNoticeById(noticeId: number): Promise<AxiosResponse> {
  return new Promise((resolve, reject) => {
    const selRole = getSelectedRole();
    const config: AxiosRequestConfig = {
      headers: {
        Authorization: `Bearer ${getAuthToken()}`,
        'X-Role-Context': selRole?.id || -1,
      },
    };
    const url = endpoint(`notices/${noticeId}`);
    axios.get(url, config)
      .then((result: any) => {
        resolve(result?.data);
      })
      .catch((error: any) => {
        errorHandler(error);
        reject(error?.response);
      });
  });
}

export function getPinnedItems(divisionId: number): Promise<AxiosResponse> {
  return new Promise((resolve, reject) => {
    const selRole = getSelectedRole();
    const config: AxiosRequestConfig = {
      headers: {
        Authorization: `Bearer ${getAuthToken()}`,
        'X-Role-Context': selRole?.id || -1,
        'X-Division-Context': divisionId,
      },
    };
    axios.get(endpoint('pinned-content'), config)
      .then((result: any) => {
        resolve(result?.data);
      })
      .catch((error: any) => {
        errorHandler(error);
        reject(error?.response);
      });
  });
}

export function getFavouriteItems(pageNumber: number = 1): Promise<AxiosResponse> {
  return new Promise((resolve, reject) => {
    const selRole = getSelectedRole();
    const config: AxiosRequestConfig = {
      headers: {
        Authorization: `Bearer ${getAuthToken()}`,
        'X-Role-Context': selRole?.id || -1,
      },
    };
    const url = endpoint(`content/favourites${pageNumber > 1 ? `?page=${pageNumber}` : ''}`);
    axios.get(url, config)
      .then((result: any) => {
        resolve(result?.data);
      })
      .catch((error: any) => {
        errorHandler(error);
        reject(error?.response);
      });
  });
}

export function toggleFavouriteNotice(id: string): Promise<AxiosResponse> {
  return new Promise((resolve, reject) => {
    const selRole = getSelectedRole();
    const config: AxiosRequestConfig = {
      headers: {
        Authorization: `Bearer ${getAuthToken()}`,
        'X-Role-Context': selRole?.id || -1,
      },
    };
    const data = { content_id: id };
    axios.post(endpoint('content/favourite'), data, config)
      .then((result: any) => {
        resolve(result?.data);
      })
      .catch((error: any) => {
        errorHandler(error);
        reject(error?.response);
      });
  });
}

export function getArchivedNotices(pageNumber: number = 1): Promise<AxiosResponse> {
  return new Promise((resolve, reject) => {
    const selRole = getSelectedRole();
    const config: AxiosRequestConfig = {
      headers: {
        Authorization: `Bearer ${getAuthToken()}`,
        'X-Role-Context': selRole?.id || -1,
      },
    };
    const url = endpoint(`notices/archived${pageNumber > 1 ? `?page=${pageNumber}` : ''}`);
    axios.get(url, config)
      .then((result: any) => {
        resolve(result?.data);
      })
      .catch((error: any) => {
        errorHandler(error);
        reject(error?.response);
      });
  });
}

export function toggleArchiveNotice(id: string): Promise<AxiosResponse> {
  return new Promise((resolve, reject) => {
    const selRole = getSelectedRole();
    const config: AxiosRequestConfig = {
      headers: {
        Authorization: `Bearer ${getAuthToken()}`,
        'X-Role-Context': selRole?.id || -1,
      },
    };
    axios.post(endpoint(`notice/${id}/archive`), {}, config)
      .then((result: any) => {
        resolve(result?.data);
      })
      .catch((error: any) => {
        errorHandler(error);
        reject(error?.response);
      });
  });
}

export function getNotificationItems(pageNumber: number = 1): Promise<AxiosResponse> {
  return new Promise((resolve, reject) => {
    const selRole = getSelectedRole();
    const config: AxiosRequestConfig = {
      headers: {
        Authorization: `Bearer ${getAuthToken()}`,
        'X-Role-Context': selRole?.id || -1,
      },
    };
    const url = endpoint(`notifications${pageNumber > 1 ? `?page=${pageNumber}` : ''}`);
    axios.get(url, config)
      .then((result: any) => {
        resolve(result?.data);
      })
      .catch((error: any) => {
        errorHandler(error);
        reject(error?.response);
      });
  });
}

export function getNotificationUnseenCount(): Promise<AxiosResponse> {
  return new Promise((resolve, reject) => {
    const selRole = getSelectedRole();
    const config: AxiosRequestConfig = {
      headers: {
        Authorization: `Bearer ${getAuthToken()}`,
        'X-Role-Context': selRole?.id || -1,
      },
    };
    const url = endpoint('notifications/unseen');
    axios.get(url, config)
      .then((result: any) => {
        resolve(result?.data);
      })
      .catch((error: any) => {
        errorHandler(error);
        reject(error?.response);
      });
  });
}

export function markNotificationAsRead(id: string): Promise<AxiosResponse> {
  return new Promise((resolve, reject) => {
    const selRole = getSelectedRole();
    const config: AxiosRequestConfig = {
      headers: {
        Authorization: `Bearer ${getAuthToken()}`,
        'X-Role-Context': selRole?.id || -1,
      },
    };
    axios.post(endpoint(`notifications/${id}`), {}, config)
      .then((result: any) => {
        resolve(result?.data);
      })
      .catch((error: any) => {
        errorHandler(error);
        reject(error?.response);
      });
  });
}

export function markAllNotificationAsRead(): Promise<AxiosResponse> {
  return new Promise((resolve, reject) => {
    const selRole = getSelectedRole();
    const config: AxiosRequestConfig = {
      headers: {
        Authorization: `Bearer ${getAuthToken()}`,
        'X-Role-Context': selRole?.id || -1,
      },
    };
    axios.post(endpoint('notifications/mark-all-as-seen-and-read'), {}, config)
      .then((result: any) => {
        resolve(result?.data);
      })
      .catch((error: any) => {
        errorHandler(error);
        reject(error?.response);
      });
  });
}

export function getWeeklyNews(divisionId: number, pageNumber: number = 1): Promise<AxiosResponse> {
  return new Promise((resolve, reject) => {
    const selRole = getSelectedRole();
    const headers: any = {
      Authorization: `Bearer ${getAuthToken()}`,
      'X-Role-Context': selRole?.id || -1,
    };

    if (divisionId > 0) {
      headers['X-Division-Context'] = divisionId;
    }
    const config: AxiosRequestConfig = { headers };
    const url = endpoint(`news${pageNumber > 1 ? `?page=${pageNumber}` : ''}`);
    axios.get(url, config)
      .then((result: any) => {
        resolve(result?.data);
      })
      .catch((error: any) => {
        errorHandler(error);
        reject(error?.response);
      });
  });
}

export function getWeeklyNewsById(newsId: number): Promise<AxiosResponse> {
  return new Promise((resolve, reject) => {
    const selRole = getSelectedRole();
    const config: AxiosRequestConfig = {
      headers: {
        Authorization: `Bearer ${getAuthToken()}`,
        'X-Role-Context': selRole?.id || -1,
      },
    };
    axios.get(endpoint(`news/${newsId}`), config)
      .then((result: any) => {
        resolve(result?.data);
      })
      .catch((error: any) => {
        errorHandler(error);
        reject(error?.response);
      });
  });
}

export function getExternalSportsCalendarLink(): Promise<AxiosResponse> {
  return new Promise((resolve, reject) => {
    const selRole = getSelectedRole();
    const config: AxiosRequestConfig = {
      headers: {
        Authorization: `Bearer ${getAuthToken()}`,
        'X-Role-Context': selRole?.id || -1,
      },
    };
    axios.get(endpoint('calendar/links'), config)
      .then((result: any) => {
        resolve(result?.data);
      })
      .catch((error: any) => {
        errorHandler(error);
        reject(error?.response);
      });
  });
}

export enum calendarEventType {
  ALL = 'ALL',
  CHILD_ONLY = 'CHILD_ONLY',
}

export function saveCalendarSettings(
  allSchoolEvents: boolean,
  allSportEvents: boolean,
  schoolEventType: calendarEventType,
  sportEventType: calendarEventType,
): Promise<AxiosResponse> {
  return new Promise((resolve, reject) => {
    const data = {
      setting_calendar_school_events: {
        is_enabled: allSchoolEvents,
        type: schoolEventType,
      },
      setting_calendar_sport_events: {
        is_enabled: allSportEvents,
        type: sportEventType,
      },
    };
    const selRole = getSelectedRole();
    const config: AxiosRequestConfig = {
      headers: {
        Authorization: `Bearer ${getAuthToken()}`,
        'X-Role-Context': selRole?.id || -1,
      },
    };
    axios.post(endpoint('user/settings/calendar'), data, config)
      .then((result: any) => {
        resolve(result?.data);
      })
      .catch((error: any) => {
        errorHandler(error);
        reject(error?.response);
      });
  });
}

export function getCalendarEvents(month: string): Promise<AxiosResponse> {
  return new Promise((resolve, reject) => {
    const selRole = getSelectedRole();
    const config: AxiosRequestConfig = {
      headers: {
        Authorization: `Bearer ${getAuthToken()}`,
        'X-Role-Context': selRole?.id || -1,
      },
    };
    const suffixUrl = `events?date=${month}`;
    axios.get(endpoint(suffixUrl), config)
      .then((result: any) => {
        resolve(result?.data);
      })
      .catch((error: any) => {
        errorHandler(error);
        reject(error?.response);
      });
  });
}

export function getCalendarEventById(eventId: number): Promise<AxiosResponse> {
  return new Promise((resolve, reject) => {
    const selRole = getSelectedRole();
    const config: AxiosRequestConfig = {
      headers: {
        Authorization: `Bearer ${getAuthToken()}`,
        'X-Role-Context': selRole?.id || -1,
      },
    };
    const url = endpoint(`events/${eventId}`);
    axios.get(url, config)
      .then((result: any) => {
        resolve(result?.data);
      })
      .catch((error: any) => {
        errorHandler(error);
        reject(error?.response);
      });
  });
}

export function getCalendarEventCategories(): Promise<AxiosResponse> {
  return new Promise((resolve, reject) => {
    const selRole = getSelectedRole();
    const config: AxiosRequestConfig = {
      headers: {
        Authorization: `Bearer ${getAuthToken()}`,
        'X-Role-Context': selRole?.id || -1,
      },
    };

    const url = endpoint('events/types');
    axios.get(url, config)
      .then((result: any) => {
        resolve(result?.data);
      })
      .catch((error: any) => {
        errorHandler(error);
        reject(error?.response);
      });
  });
}

export function getHighlights(pageNumber: number = 1): Promise<AxiosResponse> {
  return new Promise((resolve, reject) => {
    const selRole = getSelectedRole();
    const config: AxiosRequestConfig = {
      headers: {
        Authorization: `Bearer ${getAuthToken()}`,
        'X-Role-Context': selRole?.id || -1,
      },
    };
    const url = endpoint(`highlights${pageNumber > 1 ? `?page=${pageNumber}` : ''}`);
    axios.get(url, config)
      .then((result: any) => {
        resolve(result?.data);
      })
      .catch((error: any) => {
        errorHandler(error);
        reject(error?.response);
      });
  });
}

export function getArticleById(newsId: number, articleId: number): Promise<AxiosResponse> {
  return new Promise((resolve, reject) => {
    const selRole = getSelectedRole();
    const config: AxiosRequestConfig = {
      headers: {
        Authorization: `Bearer ${getAuthToken()}`,
        'X-Role-Context': selRole?.id || -1,
      },
    };
    axios.get(endpoint(`news/${newsId}/articles/${articleId}`), config)
      .then((result: any) => {
        resolve(result?.data);
      })
      .catch((error: any) => {
        errorHandler(error);
        reject(error?.response);
      });
  });
}

export function getSearch(searchValue: string, sortByRecent: boolean): Promise<AxiosResponse> {
  return new Promise((resolve, reject) => {
    const selRole = getSelectedRole();
    const config: AxiosRequestConfig = {
      headers: {
        Authorization: `Bearer ${getAuthToken()}`,
        'X-Role-Context': selRole?.id || -1,
      },
    };
    const url = endpoint(`search?search=${searchValue}${sortByRecent ? '&sort=approval_date&order=desc' : ''}`);
    axios.get(url, config)
      .then((result: any) => {
        resolve(result?.data);
      })
      .catch((error: any) => {
        errorHandler(error);
        reject(error?.response);
      });
  });
}

export function getOrderHistories(pageNumber: number): Promise<AxiosResponse> {
  return new Promise((resolve, reject) => {
    const selRole = getSelectedRole();
    const config: AxiosRequestConfig = {
      headers: {
        Authorization: `Bearer ${getAuthToken()}`,
        'X-Role-Context': selRole?.id || -1,
      },
    };
    const url = endpoint(`order-history?${pageNumber > 1 ? `&page=${pageNumber}` : ''}`);
    axios.get(url, config)
      .then((result: any) => {
        resolve(result?.data);
      })
      .catch((error: any) => {
        errorHandler(error);
        reject(error?.response);
      });
  });
}

export function getOrderById(orderId: number): Promise<AxiosResponse> {
  return new Promise((resolve, reject) => {
    const selRole = getSelectedRole();
    const config: AxiosRequestConfig = {
      headers: {
        Authorization: `Bearer ${getAuthToken()}`,
        'X-Role-Context': selRole?.id || -1,
      },
    };

    const url = endpoint(`order-history/${orderId}`);
    axios.get(url, config)
      .then((result: any) => {
        resolve(result?.data);
      })
      .catch((error: any) => {
        errorHandler(error);
        reject(error?.response);
      });
  });
}

export function getAcademicYear(): Promise<AxiosResponse> {
  return new Promise((resolve, reject) => {
    const config: AxiosRequestConfig = {
      headers: {
        Authorization: `Bearer ${getAuthToken()}`,
      },
    };

    const url = endpoint('academic-years');
    axios.get(url, config)
      .then((result: any) => {
        resolve(result?.data);
      })
      .catch((error: any) => {
        errorHandler(error);
        reject(error?.response);
      });
  });
}

export function getGallery(
  studentId: number,
  categoryId: number,
  academicYearId: number[],
  pageNumber: number,
): Promise<AxiosResponse> {
  return new Promise((resolve, reject) => {
    const selRole = getSelectedRole();
    const params: any = { category: categoryId };
    if (pageNumber > 1) {
      // eslint-disable-next-line dot-notation
      params['page'] = pageNumber;
    }
    if (academicYearId.length > 0) {
      // eslint-disable-next-line dot-notation
      params['filters'] = { academic_years: academicYearId };
    }
    const config: AxiosRequestConfig = {
      headers: {
        Authorization: `Bearer ${getAuthToken()}`,
        'X-Role-Context': selRole?.id || -1,
        'X-Student-Context': studentId,
      },
    };

    const url = endpoint(`gallery?${Qs.stringify(params)}`);
    axios.get(url, config)
      .then((result: any) => {
        resolve(result?.data);
      })
      .catch((error: any) => {
        errorHandler(error);
        reject(error?.response);
      });
  });
}

export function reportGalleryImage(studentId: number, imgId: number): Promise<AxiosResponse> {
  return new Promise((resolve, reject) => {
    const data = { image_id: imgId };
    const selRole = getSelectedRole();
    const config: AxiosRequestConfig = {
      headers: {
        Authorization: `Bearer ${getAuthToken()}`,
        'X-Role-Context': selRole?.id || -1,
        'X-Student-Context': studentId,
      },
    };

    axios.post(endpoint('gallery/report'), data, config)
      .then((result: any) => {
        resolve(result?.data);
      })
      .catch((error: any) => {
        errorHandler(error);
        reject(error?.response);
      });
  });
}

export function createPaymentIntent(amount: number): Promise<AxiosResponse> {
  return new Promise((resolve, reject) => {
    const data = { amount };
    const selRole = getSelectedRole();
    const config: AxiosRequestConfig = {
      headers: {
        Authorization: `Bearer ${getAuthToken()}`,
        'X-Role-Context': selRole?.id || -1,
      },
    };

    axios.post(endpoint('orders/intent'), data, config)
      .then((result: any) => {
        resolve(result?.data);
      })
      .catch((error: any) => {
        errorHandler(error);
        reject(error?.response);
      });
  });
}

export function createPaymentOrder(orderData: any): Promise<AxiosResponse> {
  return new Promise((resolve, reject) => {
    const selRole = getSelectedRole();
    const config: AxiosRequestConfig = {
      headers: {
        Authorization: `Bearer ${getAuthToken()}`,
        'X-Role-Context': selRole?.id || -1,
      },
    };

    axios.post(endpoint('order'), orderData, config)
      .then((result: any) => {
        resolve(result?.data);
      })
      .catch((error: any) => {
        errorHandler(error);
        reject(error?.response);
      });
  });
}

export function getVimeoThumbnail(url: string): Promise<AxiosResponse> {
  return new Promise((resolve, reject) => {
    const vimeoUrl = `https://vimeo.com/api/oembed.json?url=${url}`;
    axios.get(vimeoUrl)
      .then((result: any) => {
        resolve(result?.data);
      })
      .catch((error: any) => {
        reject(error?.response);
      });
  });
}

export function getVideoThumbnailByUrl(url: string): Promise<string> {
  return new Promise((resolve, reject) => {
    if (url.toLowerCase().indexOf('youtube.com') > -1) {
      const results = url.match('[\\?&]v=([^&#]*)');
      const videoId = results === null ? url : results[1];
      const thumPath = `http://img.youtube.com/vi/${videoId}/0.jpg`;
      resolve(thumPath);
    } else if (url.toLowerCase().indexOf('vimeo.com') > -1) {
      const vimeoUrl = `https://vimeo.com/api/oembed.json?url=${url}`;
      axios.get(vimeoUrl)
        .then((result: any) => {
          if (result?.data?.video_id) {
            resolve(result?.data?.thumbnail_url || '');
          } else {
            reject(new Error('Error fetching thumbnail'));
          }
        })
        .catch((error: any) => {
          reject(new Error(error?.response?.message || 'Error fetching thumbnail'));
        });
    } else {
      reject(new Error('Error fetching thumbnail'));
    }
  });
}

export function forceDownloadFile(url: string, fileName: string = ''): Promise<string> {
  return new Promise((resolve, reject) => {
    axios({
      url,
      method: 'GET',
      responseType: 'blob',
    })
      .then((response) => {
        const fileUrl = window.URL.createObjectURL(new Blob([response.data]));
        if (fileUrl) {
          const link = document.createElement('a');
          link.href = fileUrl;
          let fileTitle = fileName;
          if (!fileTitle) {
            // @ts-ignore
            fileTitle = url?.split('\\').pop().split('/').pop() || 'file.jpg';
          }
          link.setAttribute('download', fileTitle);
          link.click();
        }
        resolve(fileUrl);
      }).catch((error: any) => {
        reject(new Error(error?.response?.message || 'Error in downloading file'));
      });
  });
}

export function getMagazines(divisionId: number): Promise<AxiosResponse> {
  return new Promise((resolve, reject) => {
    const selRole = getSelectedRole();
    const headers: any = {
      Authorization: `Bearer ${getAuthToken()}`,
      'X-Role-Context': selRole?.id || -1,
    };

    if (divisionId > 0) {
      headers['X-Division-Context'] = divisionId;
    }
    const config: AxiosRequestConfig = { headers };
    axios.get(endpoint('magazines'), config)
      .then((result: any) => {
        resolve(result?.data);
      })
      .catch((error: any) => {
        errorHandler(error);
        reject(error?.response);
      });
  });
}

export function sendViewTime(type: number, id: string, secTime: number): Promise<AxiosResponse> {
  return new Promise((resolve, reject) => {
    const selRole = getSelectedRole();
    if (selRole && (selRole.name.trim().toLowerCase() === 'guardian' || selRole.name.trim().toLowerCase() === 'vip')) {
      const config: AxiosRequestConfig = {
        headers: {
          Authorization: `Bearer ${getAuthToken()}`,
          'X-Role-Context': selRole?.id || -1,
        },
      };

      let entity = '';
      if (type === 1) {
        entity = 'highlight';
      } else if (type === 2) {
        entity = 'notice';
      } else if (type === 3) {
        entity = 'article';
      }

      const data = { time: secTime };
      axios.post(endpoint(`users/stat/view/${entity}/${id}`), data, config)
        .then((result: any) => {
          resolve(result?.data);
        })
        .catch((error: any) => {
          errorHandler(error);
          reject(error?.response);
        });
    }
  });
}

export function getSearchKeywords(): Promise<AxiosResponse> {
  return new Promise((resolve, reject) => {
    const selRole = getSelectedRole();
    const config: AxiosRequestConfig = {
      headers: {
        Authorization: `Bearer ${getAuthToken()}`,
        'X-Role-Context': selRole?.id || -1,
      },
    };
    axios.get(endpoint('user/keywords'), config)
      .then((result: any) => {
        resolve(result?.data);
      })
      .catch((error: any) => {
        errorHandler(error);
        reject(error?.response);
      });
  });
}

export function getShareLink(id: string, type: string): Promise<AxiosResponse> {
  return new Promise((resolve, reject) => {
    const selRole = getSelectedRole();
    const config: AxiosRequestConfig = {
      headers: {
        Authorization: `Bearer ${getAuthToken()}`,
        'X-Role-Context': selRole?.id || -1,
      },
    };
    const url = `${endpoint('content/share/url')}?content_id=${id}&content_type=${type}`;
    axios.get(url, config)
      .then((result: any) => {
        resolve(result?.data);
      })
      .catch((error: any) => {
        errorHandler(error);
        reject(error?.response);
      });
  });
}

export function sendShareEmail(
  email: string,
  subject: string,
  body: string,
  token: string,
): Promise<AxiosResponse> {
  return new Promise((resolve, reject) => {
    const selRole = getSelectedRole();
    const config: AxiosRequestConfig = {
      headers: {
        Authorization: `Bearer ${getAuthToken()}`,
        'X-Role-Context': selRole?.id || -1,
      },
    };

    const data = {
      email,
      subject,
      body,
      token,
    };
    axios.post(endpoint('content/share/email'), data, config)
      .then((result: any) => {
        resolve(result?.data);
      })
      .catch((error: any) => {
        errorHandler(error);
        reject(error?.response);
      });
  });
}

export function getShareContent(token: string): Promise<AxiosResponse> {
  return new Promise((resolve, reject) => {
    const url = `${endpoint('content/share/get')}?token=${token}`;
    axios.get(url)
      .then((result: any) => {
        resolve(result?.data);
      })
      .catch((error: any) => {
        // errorHandler(error);
        reject(error?.response);
      });
  });
}
